import { FC, useEffect, useState } from 'react';

import Layout from 'components/layouts/Layout';
import About from 'components/sections/About';
import Contact from 'components/sections/Contact';
import Experience from 'components/sections/Experience';
import Main from 'components/sections/Main';
import Projects from 'components/sections/Projects';

import 'styles/global.scss';
import AppHelmet from 'components/sections/AppHelmet';

export enum ScrollDirections {
  downscroll = 'down',
  upscroll = 'up',
}

export interface ScrollData {
  scrollTop: number;
  scrollDir: ScrollDirections;
}

const App: FC = () => {
  const [scrollData, setScrollData] = useState<ScrollData>({
    scrollTop: 0,
    scrollDir: ScrollDirections.downscroll,
  });

  let lastScrollTop = 0;
  const handleScroll = () => {
    let scrollDir = ScrollDirections.downscroll;
    const scrollTopPos =
      window.scrollY !== undefined
        ? window.scrollY
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    if (scrollTopPos > lastScrollTop) {
      scrollDir = ScrollDirections.downscroll;
    } else {
      scrollDir = ScrollDirections.upscroll;
    }
    lastScrollTop = scrollTopPos <= 0 ? 0 : scrollTopPos;
    setScrollData({ ...scrollData, scrollTop: scrollTopPos, scrollDir });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: false });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // eslint-disable-line

  return (
    <div className='App'>
      <AppHelmet />
      <Layout scrollData={scrollData}>
        <Main />
        <About />
        <Experience />
        <Projects />
        <Contact />
      </Layout>
    </div>
  );
};

export default App;
