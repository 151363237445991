import { FC } from 'react';

interface ContactProps {}

const Contact: FC<ContactProps> = (props) => {
  return (
    <div className='contact-wrapper' id='contact'>
      <div className='app-container contact-section'>
        <div className='app-section-header'>
          <h2 className='ash-no'>04.</h2>
          <h3 className='ash-title'>What's Next?</h3>
        </div>
        <div className='contact-contents'>
          <h1 className='cc-header'>Get In Touch</h1>
          <p className='cc-text'>
            Currently I’m looking for a new and challenging opportunity. If you have something for
            me, or have a question, or just want to say hi, my inbox is always open. I’ll try my
            best to get back to you!
          </p>
          <div className='say-hello-button-wrapper'>
            <a
              href='mailto:shindeshubhamm@gmx.de'
              rel='noreferrer'
              target='_blank'
              className='app-btn say-hello-button'
            >
              Say Hello
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
