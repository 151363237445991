import { FC } from 'react';
import { Helmet } from 'react-helmet';

const AppHelmet: FC = () => {
  return (
    <Helmet defaultTitle='Shubham Shinde'>
      <meta charSet='utf-8' />
      <title>Shubham Shinde</title>
      <link rel='canonical' href='https://shubhamshinde.com' />
      <meta
        name='description'
        content='Shubham Shinde is a Software Engineer who specializes in designing and building web applications.'
      />
      <meta property='og:title' content='Shubham Shinde' />
      <meta
        property='og:description'
        content='Shubham Shinde is a Software Engineer who specializes in designing and building web applications.'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://shubhamshinde.com/' />
      <meta
        property='og:image'
        content='https://shubhamshinde.com/images/home.png'
      />
      <meta property='og:image:type' content='image/png' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
    </Helmet>
  );
};

export default AppHelmet;
