import { FC, ReactNode, CSSProperties } from 'react';
import { RiGithubFill, RiLinkedinFill, RiWhatsappFill } from 'react-icons/ri';

// import { ResumePDF } from 'docs/resume.pdf';

import { ScrollData, ScrollDirections } from 'App';

interface LayoutProps {
  children: ReactNode;
  scrollData: ScrollData;
}

const menuItems = [
  { name: 'About', link: 'about' },
  { name: 'Experience', link: 'experience' },
  { name: 'Projects', link: 'projects' },
  { name: 'Contact', link: 'contact' },
];

const socialMedia = [
  {
    name: 'LinkedIn',
    link: 'https://linkedin.com/in/shindeshubhamm',
    icon: RiLinkedinFill,
  },
  {
    name: 'GitHub',
    link: 'https://github.com/shindeshubhamm',
    icon: RiGithubFill,
  },
  {
    name: 'WhatsApp',
    link: 'https://wa.me/4915147976902',
    icon: RiWhatsappFill,
  },
];

const Layout: FC<LayoutProps> = (props) => {
  const { children, scrollData } = props;

  const { scrollTop, scrollDir } = scrollData;

  const currentYear: number = new Date().getFullYear();

  const appearTopAnimation = (time: number): string => {
    return `fadefromtop ${time}ms linear`;
  };

  return (
    <div className='layout'>
      <header
        className={`l-navbar`}
        style={
          {
            transform: `translateY(${
              scrollDir === ScrollDirections.downscroll
                ? scrollTop < 200
                  ? scrollTop * -0.8
                  : -84
                : 0
            }px)`,
            boxShadow:
              scrollDir === ScrollDirections.upscroll && scrollTop !== 0
                ? '1px 0 4px 2px #001f4838'
                : 'none',
          } as CSSProperties
        }
      >
        <a href='/#' className='l-logo-wrapper'>
          <img src='/images/ss.svg' className='logo' alt='ShindeShubhamm' />
        </a>
        <div className='l-navmenu'>
          {menuItems.map((item, index) => {
            const { name, link } = item;
            return (
              <a
                href={`#${link}`}
                className={`menu-item`}
                style={
                  {
                    animation: appearTopAnimation(200 * (index + 1)),
                  } as CSSProperties
                }
                key={index}
              >
                <div className='sr-no'>0{index + 1}.</div>
                <div className='name'>{name}</div>
              </a>
            );
          })}
          <a
            href='/resume.pdf'
            target='_blank'
            className='resume-button app-btn'
            rel='noreferrer'
            style={
              {
                animation: appearTopAnimation(1000),
              } as CSSProperties
            }
          >
            Resume
          </a>
        </div>
      </header>
      {children}
      <div className={`social-icons app-appear`}>
        <div className='icon-holder'>
          {socialMedia.map((media) => {
            const { link, name, icon: Icon } = media;

            return (
              <a
                href={link}
                target='_blank'
                className='si-btn'
                rel='noreferrer'
                title={name}
                key={name}
              >
                <Icon className='icon' />
              </a>
            );
          })}
        </div>
        <div className='line-design'></div>
      </div>
      <div className={`email-contact app-appear`}>
        <a href='mailto:shindeshubhamm@gmx.de' className='email-link'>
          <p className='email-id'>shindeshubhamm@gmx.de</p>
        </a>
        <div className='line-design'></div>
      </div>
      <div className='footer'>
        <div className='design-credits'>
          Designed & Built by{' '}
          <a href='#top' className='design-credits-name'>
            Shubham Shinde
          </a>
        </div>
        <div className='copyrights'>&copy; {currentYear} Shubham Shinde. All Rights Reserved.</div>
      </div>
    </div>
  );
};

export default Layout;
