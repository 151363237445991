import { FC } from 'react';

interface MainProps {}

interface me {
  title: string;
}

const iam: me[] = [
  { title: 'Software Developer' },
  { title: 'UI Designer' },
  { title: 'UX Engineer' },
];

const Main: FC<MainProps> = () => {
  return (
    <div className='main-wrapper' id='top'>
      <div className='app-container main-section'>
        <div className='center-section'>
          <div className='hello-wrapper'>
            <p className='hello'>Hello.</p>
            <img src='/images/wave.png' className='wave' alt='Waving Hand' />
            <p className='hello'>My name is</p>
          </div>
          <h1 className='name'>
            Shubham <span className='lastname'>Shinde</span>
          </h1>
          <div className='short-intro-wrapper'>
            <div className='short-intro-1'>
              <span className='short-intro'>I'm a</span>
              <div className='scrolling-wrapper'>
                <div className='scrolling-elements'>
                  {iam.map((item, index) => {
                    const { title } = item;
                    return (
                      <p key={index} className='scrolling'>
                        {title}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
            <p className='short-intro'>based in Germany!</p>
          </div>
        </div>
        <a href='/#about' className='symbol-wrapper'>
          <div className='symbol'>
            <div className='symbol-dot'></div>
          </div>
          <span className='symbol-text'>SCROLL</span>
        </a>
      </div>
    </div>
  );
};

export default Main;
