import { FC } from 'react';
import { RiGithubLine } from 'react-icons/ri';
import { FiExternalLink } from 'react-icons/fi';
import { HiFolder } from 'react-icons/hi';

interface ProjectsProps {}

interface Project {
  name: string;
  githubUrl?: string;
  productionUrl?: string;
  info: string;
  technologies: string[];
}

const projectsData: Project[] = [
  {
    name: 'Domiciliary Care Management System',
    githubUrl: 'https://github.com/ShindeShubhamm/e-hospital-frontend',
    productionUrl: 'https://rxonline.netlify.app',
    info: 'System for digitization of Hospital works such as medical history management, quick medical assistance and prescription issues.',
    technologies: ['Node.js', 'React', ' M-UI', 'SASS'],
  },
  {
    name: 'Resume Builder',
    githubUrl: 'https://github.com/ShindeShubhamm/print-resume',
    info: 'Personal Resume builder with certain data such as Education, Experience, and Personal Data as input.',
    technologies: ['Node.js', 'React', 'SASS'],
  },
  {
    name: 'Google Keep Clone',
    githubUrl: 'https://github.com/ShindeShubhamm/g-keep',
    productionUrl: 'https://googlenotes.netlify.app/',
    info: 'A simple Google Keep Clone built with ReactJS and pure SASS.',
    technologies: ['React', 'SASS'],
  },
];

const Projects: FC<ProjectsProps> = (props) => {
  return (
    <div className='projects-wrapper' id='projects'>
      <div className='app-container projects-section'>
        <div className='app-section-header app-section-header-blue'>
          <h2 className='ash-no'>03.</h2>
          <h3 className='ash-title'>Some Things I've Built</h3>
        </div>
        <div className='projects-contents'>
          <div className='project-viewer-wrapper'>
            {projectsData.map((project, index) => {
              const { info, name, technologies, githubUrl, productionUrl } =
                project;

              return (
                <div className='project-viewer' key={`project-${index}`}>
                  <div className='card-top'>
                    <div className='pv-header-wrapper'>
                      <div className='pvh-folder'>
                        <HiFolder className='pvhf-icon' />
                      </div>
                      <div className='pvh-links'>
                        {githubUrl && (
                          <a
                            href={githubUrl}
                            target='_blank'
                            rel='noreferrer'
                            className='icon-link'
                            title='Github URL'
                          >
                            <RiGithubLine className='link-icon' />
                          </a>
                        )}
                        {productionUrl && (
                          <a
                            href={productionUrl}
                            target='_blank'
                            rel='noreferrer'
                            className='icon-link'
                            title='View Live'
                          >
                            <FiExternalLink className='link-icon' />
                          </a>
                        )}
                      </div>
                    </div>
                    <h2 className='pv-title'>{name}</h2>
                    <p className='pv-info'>{info}</p>
                  </div>
                  <div className='pv-tech'>
                    {technologies.map((tech, idx) => {
                      return (
                        <span key={idx} className='pvt-tech'>
                          {tech}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className='show-more-button-wrapper'>
            <a
              href='https://github.com/shindeshubhamm?tab=repositories'
              rel='noreferrer'
              target='_blank'
              className='app-btn show-more-button'
            >
              View More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
