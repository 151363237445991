import { FC } from 'react';
import { BsFillTriangleFill } from 'react-icons/bs';

interface AboutProps {}

interface Skill {
  name: string;
}

const skills: Skill[] = [
  { name: 'JavaScript (ES6+)' },
  { name: 'TypeScript' },
  { name: 'React' },
  { name: 'CSS/SASS' },
  { name: 'Node.js' },
  { name: 'Figma & Inkscape' },
];

const About: FC<AboutProps> = (props) => {
  return (
    <div className='about-wrapper' id='about'>
      <div className='app-container about-section'>
        <div className='app-section-header app-section-header-blue'>
          <h2 className='ash-no'>01.</h2>
          <h3 className='ash-title'>About Me</h3>
        </div>
        <div className='about-contents'>
          <div className='about-text'>
            <h3 className='at-text'>
              Hi. My name is <span className='at-highlight'>Shubham</span> and I
              enjoy developing web applications. My interest in coding was
              inculcated in me back in 2016 when I started learning HTML & CSS.
            </h3>
            <h3 className='at-text'>
              Fast-forward to today - I'm a professional{' '}
              <span className='at-highlight'>Software Developer</span>. I've had
              the privilege of working with a{' '}
              <span className='at-highlight'>startup</span> as well as a
              well-established{' '}
              <span className='at-highlight'>product-based company</span>!
            </h3>
            <h3 className='at-text'>
              Here are a few tools & technologies I've been working with
              recently:
            </h3>
            <div className='at-skills-wrapper'>
              {skills.map((skill, index) => {
                const { name } = skill;

                return (
                  <div className='skill-wrapper' key={`skill-${index}`}>
                    <BsFillTriangleFill className='sk-icon' />
                    <div className='sk-name'>{name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='about-image'>
            <img
              src='/images/square.png'
              alt='Shubham Shinde'
              className='ai-image'
            />
            <div className='ai-outline'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
