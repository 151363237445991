import { FC, useState } from 'react';
import { BsFillTriangleFill } from 'react-icons/bs';

interface ExperienceProps {}

enum Organizations {
  AXESS_INTELLIGENCE = 'Axess Intelligence GmbH',
  DIGITE_INC = 'Digité Inc.',
  TALENTEXCEL = 'TalentExcel',
}

type WorkExpUrl = {
  text: string;
  link: string;
};

type ExpWorkDetail = {
  content: string; // %url% to be replaced with data at url[index]
  url?: WorkExpUrl[];
};

type ExpDetails = {
  orgName: string;
  position: string;
  from: string;
  to: string;
  toolsAndTech: string[];
  details: ExpWorkDetail[];
  orgUrl: string;
};

const getExpDetails = (orgName: Organizations): ExpDetails => {
  switch (orgName) {
    case Organizations.AXESS_INTELLIGENCE:
      return {
        orgName: 'Axess Intelligence GmbH',
        position: 'Software Developer',
        from: 'March 2023',
        to: 'Present',
        orgUrl: 'https://axessintelligence.com',
        toolsAndTech: [
          'Flutter',
          'React Native',
          'TypeScript',
          'Next JS',
          'Semantic UI',
          'Figma',
          'Git',
          'Python',
        ],
        details: [
          {
            content:
              'Develop mobile applications using Flutter, ensuring cross-platform compatibility and optimal performance.',
          },
          {
            content:
              'Previously utilized React Native to create dynamic and user-friendly mobile interfaces for diverse client projects.',
          },
          {
            content:
              'Contributed to the development of a platform using Next JS, leveraging its server-side rendering capabilities for enhanced performance.',
          },
          {
            content:
              'Proficient in UI/UX design using Figma and Inkscape, collaborating closely with stakeholders to create visually appealing and intuitive interfaces.',
          },
        ],
      };

    case Organizations.DIGITE_INC:
      return {
        orgName: 'Digité Inc.',
        position: 'UX Engineer',
        from: 'January 2021',
        to: 'July 2022',
        orgUrl: 'https://digite.com',
        toolsAndTech: [
          'TypeScript',
          'JavaScript',
          'React',
          'Redux',
          'CSS/SASS',
          'Material UI',
          'Semantic UI',
          'Figma & Inkscape',
          'Git',
          'Docker (Beginner)',
          'Node.js - Express',
        ],
        details: [
          {
            content:
              'Ideated over user experience and created prototypes using Figma & Inkscape for a chatbot product (%url%) from scratch.',
            url: [{ link: 'https://kairon.digite.com', text: 'Kairon' }],
          },
          {
            content:
              'Worked with a variety of different languages, tools and technologies such as JavaScript, TypeScript, React, Redux, CSS/SASS and Material UI.',
          },
          {
            content:
              'Collaborated with multi-disciplinary teams of engineers, marketing, and sales on a daily basis.',
          },
          {
            content: 'Wrote modern, performant, maintainable code for a diverse array projects.',
          },
        ],
      };

    case Organizations.TALENTEXCEL:
      return {
        orgName: 'TalentExcel',
        position: 'Fullstack Developer',
        from: 'June 2020',
        to: 'December 2021',
        orgUrl: 'http://10xGrowth.com',
        toolsAndTech: [
          'JavaScript',
          'NextJS',
          'Redux',
          'Material UI',
          'CSS/SASS',
          'Git',
          'Loopback.js',
          'MongoDB',
          'Rest API',
          'Google Firebase',
          'Node.js - Express',
        ],
        details: [
          {
            content:
              'Built API services for various features of the platform using NodeJS, Google Firebase and MongoDB.',
          },
          {
            content:
              'Used technologies like NextJS, ReactJS, Material UI and SASS to create attractive interfaces.',
          },
          {
            content: 'Standardized existing code with a responsive, mobile-first approach.',
          },
          {
            content:
              'Formulated and implemented performance improvement techniques, minimizing page load time.',
          },
          {
            content: 'Collaborated with product team members to implement new feature development.',
          },
        ],
      };
  }
};

interface OrganizationState {
  index: number;
  name: Organizations;
  data: ExpDetails;
}

const Experience: FC<ExperienceProps> = (props) => {
  const [selectedOrg, setSelectedOrg] = useState<OrganizationState>({
    name: Organizations.AXESS_INTELLIGENCE,
    index: 0,
    data: getExpDetails(Organizations.AXESS_INTELLIGENCE),
  });

  const getWorkExpDetailContent = (detail: ExpWorkDetail): JSX.Element => {
    const { content, url } = detail;
    let replacedContent = content;
    let urlIndex = 0;

    while (replacedContent.includes('%url%') && url && url[urlIndex]) {
      replacedContent = replacedContent.replace(
        '%url%',
        `<a href="${url[urlIndex].link}" target="_blank" rel="noreferrer">${url[urlIndex].text}</a>`
      );
      urlIndex++;
    }

    const data = {
      __html: replacedContent,
    };

    return <span dangerouslySetInnerHTML={data}></span>;
  };

  return (
    <div className='exp-wrapper' id='experience'>
      <div className='app-container exp-section'>
        <div className='app-section-header'>
          <h2 className='ash-no'>02.</h2>
          <h3 className='ash-title'>Where I've Worked</h3>
        </div>
        <div className='exp-contents'>
          <div className='org-menu'>
            {(Object.keys(Organizations) as Array<keyof typeof Organizations>).map((org, index) => {
              return (
                <button
                  key={index}
                  className={`org-menu-item ${
                    Organizations[org] === selectedOrg.name ? 'org-selected' : ''
                  }`}
                  onClick={() =>
                    setSelectedOrg({
                      name: Organizations[org],
                      index,
                      data: getExpDetails(Organizations[org]),
                    })
                  }
                >
                  {Organizations[org]}
                </button>
              );
            })}
            <div
              className='selection-indicator'
              style={{ top: `calc(${selectedOrg.index} * var(--tab-height))` }}
            ></div>
          </div>
          <div className='org-details'>
            <h1 className='org-name'>
              {selectedOrg.data.position}{' '}
              <a
                href={selectedOrg.data.orgUrl}
                target='_blank'
                rel='noreferrer'
                className='org-link'
              >
                @ {selectedOrg.data.orgName}
                <div className='org-link-bg'></div>
              </a>
            </h1>
            <p className='org-duration'>
              {selectedOrg.data.from} - {selectedOrg.data.to}
            </p>
            <div className='od-wrapper'>
              {selectedOrg.data.details.map((detail, index) => {
                return (
                  <div className='detail-wrapper' key={`detail-${index}`}>
                    <div className='dw-icon-wrapper'>
                      <BsFillTriangleFill className='dw-icon' />
                    </div>
                    <p className='dw-name'>
                      {detail.content.includes('%url%')
                        ? getWorkExpDetailContent(detail)
                        : detail.content}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='org-skills'>
            <h2 className='os-title'>Tools & Technologies</h2>
            {selectedOrg.data.toolsAndTech.map((tool, index) => {
              return (
                <div className='org-skill-item' key={`${tool}-${index}`}>
                  <BsFillTriangleFill className='tt-icon' />
                  <span className='tt-name'>{tool}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
